import {useEffect, useState} from "react";

//@@@ type=wrapper
//@@@ dependency=first

function ServiceWorkerWrapper({Next, context}) {
    const [install, setInstall] = useState(null);

    const serviceWorkerStart = async () => {
        if (!('serviceWorker' in navigator)) {
            return null;
        }
        const swUrl = `${process.env.PUBLIC_URL}/serviceWorker.js`;
        return await navigator.serviceWorker.register(swUrl);
    };

    const handler = e => {
        e.preventDefault();
        setInstall(e);
    };

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", handler);

        serviceWorkerStart();
        return () => window.removeEventListener("transitionend", handler);
    }, []);

    return <Next context={{...context, install}} />
}
export default ServiceWorkerWrapper;