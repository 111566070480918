import {gql} from "@apollo/client";

export const GroupCampaignFragment = gql`
    fragment GroupCampaignFragment on GroupCampaign {
		id
		code
		description
		created_at
    }
`

export const GroupCampaignFragmentAdmin = gql`
    fragment GroupCampaignFragmentAdmin on GroupCampaign {
		id
		code
		description
		created_at
		products_views
		wishlist_added
    }
`

export const CreateGroupCampaign = gql`
	${GroupCampaignFragment}
     mutation ($group_id: ID!, $code: String!, $description: String) {
         CreateGroupCampaign (group_id: $group_id, code: $code, description: $description) {
			...GroupCampaignFragment
         }
    }
`

export const DeleteGroupCampaign = gql`
	${GroupCampaignFragment}
     mutation ($id: ID!) {
         DeleteGroupCampaign (id: $id) {
			...GroupCampaignFragment
         }
    }
`

