import config from "../../_config";


export default function link(image, lg=false) {
    if(!image.width)    {
        return `${config.storageFileFolder}/${image.bucket}/${image.folder}/${image.name}`;
    }
    let imageName = image.name;
    if(lg)  {
        const imageArr = imageName.split(".");
        const mime = imageArr.pop();
        imageName = imageArr.join(".") + "-lg." + mime;
    }
    return `${config.storageImageFolder}/${image.bucket}/${image.folder}/${imageName}`;
}
