import {gql} from "@apollo/client";
import {GroupCampaignFragmentAdmin} from "./GroupCampaign";

export const GroupFragment = gql`
    fragment GroupFragment on Group {
		id
		name
		description
		filters
		created_at
    }
`

export const GroupFragmentAdmin = gql`
    fragment GroupFragmentAdmin on Group {
		id
		name
		description
		filters
		created_at
    }
`

export const GetGroups = gql`
	${GroupFragment}
     query ($search: String, $withName: Boolean) {
         GetGroups (search: $search, withName: $withName) {
			...GroupFragment
         }
    }
`

export const GetGroupsAdmin = gql`
	${GroupFragmentAdmin}
	${GroupCampaignFragmentAdmin}
     query ($search: String, $withName: Boolean) {
         GetGroups (search: $search, withName: $withName) {
			...GroupFragmentAdmin
			campaigns 	 { 
				...GroupCampaignFragmentAdmin 
			}
         }
    }
`

export const CreateGroup = gql`
	${GroupFragment}
     mutation ($name: String, $description: String) {
         CreateGroup (name: $name, description: $description) {
			...GroupFragment
         }
    }
`

export const UpdateGroup = gql`
	${GroupFragment}
     mutation ($id: ID!, $name: String, $description: String, $position: Int, $filters: String) {
         UpdateGroup (id: $id, name: $name, description: $description, position: $position, filters: $filters) {
			...GroupFragment
         }
    }
`

export const DeleteGroup = gql`
	${GroupFragment}
     mutation ($id: ID!) {
         DeleteGroup (id: $id) {
			...GroupFragment
         }
    }
`

