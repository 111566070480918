import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";
import {StorageFragmentAdmin} from "./Storage";

export const SettingFragment = gql`
	${StorageFragment}
    fragment SettingFragment on Setting {
		id
		field
		value
		values
		hidden
		image 	 { 
			...StorageFragment 
		}
    }
`

export const SettingFragmentAdmin = gql`
	${StorageFragmentAdmin}
    fragment SettingFragmentAdmin on Setting {
		id
		field
		value
		values
		hidden
		image 	 { 
			...StorageFragmentAdmin 
		}
    }
`

export const GetSettings = gql`
	${SettingFragment}
     query  {
         GetSettings  {
			...SettingFragment
         }
    }
`

export const GetSettingsAdmin = gql`
	${SettingFragmentAdmin}
     query  {
         GetSettings  {
			...SettingFragmentAdmin
         }
    }
`

export const UpsertSetting = gql`
	${SettingFragment}
     mutation ($id: ID, $field: String, $value: String, $image_id: ID) {
         UpsertSetting (id: $id, field: $field, value: $value, image_id: $image_id) {
			...SettingFragment
         }
    }
`

