import {useContext} from "react";
import AppContext from "../../_app/AppContext";

function useGetMenu(menu)    {
    const {lang, pages, route} = useContext(AppContext);

    const holder = [];

    for(const name in pages) {
        const item = pages[name].page;
        if(item.menu !== menu) continue;
        if(!item.active) continue;
        if(item.parent_id) continue;
        holder.push({
            id: item.id,
            name: item.names[lang],
            path: route(`page.${item.id}`),
            children: item.children || []
        });
    }

    return holder;
}
export default useGetMenu;
