import {useLocation, useNavigate} from "react-router-dom";
import {useContext} from "react";
import AppContext from "../../_app/AppContext";

function useQueryParser() {
    const {trans} = useContext(AppContext);
    const location = useLocation();
    let navigate = useNavigate();
    const queryParams =  new URLSearchParams(decodeURIComponent(location.search));

    const brandsParamName = trans("param.brands");
    const orderParamName = trans("param.order");
    const filtersParamName = trans("param.filters");

    let page = queryParams.get("page") ? parseInt(queryParams.get("page")) : undefined;
    let order = queryParams.get(orderParamName) ? queryParams.get(orderParamName) : undefined;

    let brands = [];
    if(queryParams.get(brandsParamName))  {
        const split = queryParams.get(brandsParamName).split(",");
        brands = split.map(item => decodeURIComponent(item));
    }

    let filters = {};
    if(queryParams.get(filtersParamName))  {
        const filtersParams = queryParams.get(filtersParamName);
        const split = filtersParams.split("-");
        for(let i=0; i<split.length; i++)   {
            const arr = split[i].split(":");
            if(arr[0] && arr[0])  {
                filters[arr[0]] = arr[1].split(",");
            }
        }
    }

    const link = (field, value) => {
        const string = url(field, value);
        navigate(string);
    }

    const url = (field, value) => {
        let string = location.pathname;
        const params = {};

        const pageField = field === "page" ? value : page;
        if(pageField)   {
            params.page = pageField;
        }

        const orderField = field === orderParamName ? value : order;
        if(orderField)   {
            params[orderParamName] = orderField;
        }

        let newBrands = [...brands];
        if(field === brandsParamName)      {
            const checked = value.target.checked;
            const name = value.target.value;
            delete params.page;
            if(checked) {
                if(!newBrands.includes(name))   {
                    newBrands.push(encodeURIComponent(name));
                }
            } else {
                newBrands = newBrands.filter(it => it !== name);
            }
        }
        if(newBrands.length > 0)    {
            params[brandsParamName] = newBrands.join(",");
        }

        let newFilters = {...filters};
        if(field === filtersParamName)      {
            const checked = value.target.checked;
            const parent = value.target.name;
            const id = value.target.value;
            if(checked) {
                if(!filters[parent])    {
                    newFilters[parent] = [];
                }
                newFilters[parent].push(id);
            }   else  {
                if(filters[parent] && filters[parent].includes(id)) {
                    if(filters[parent].length === 1)    {
                        delete newFilters[parent];
                    } else  {
                        const inner = filters[parent].filter(it => it !== id);
                        newFilters = {...newFilters, [parent]: inner};
                    }
                }
            }
        }
        if(Object.keys(newFilters).length > 0)  {
            let url = [];
            for(let key in newFilters)   {
                url.push(`${key}:${newFilters[key].join(",")}`);
            }
            if(url.length > 0) {
                params[filtersParamName] = url.join("-");
            }
        }

        if(Object.keys(params).length > 0)  {
            string += "?" + Object.keys(params).map(key => `${key}=${params[key]}`).join("&");
        }
        return string;
    }
    return {brands, filters, page, order, url, link}
}
export default useQueryParser;