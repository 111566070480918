import {ApolloClient, ApolloProvider, from, InMemoryCache} from "@apollo/client";
import {createUploadLink} from "apollo-upload-client";
import {setContext} from "@apollo/client/link/context";
import Wrappers from "./-   Wrappers";
import config from "../_config";


export function uploadLink() {
    function customFetch(url, opts = {}) {  // LOAD PERCENTAGE CALCULATE (FOR STORAGE)
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()

            xhr.open(opts.method || 'get', url)

            for (let k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k])

            xhr.onload = e =>
                resolve({
                    ok: true,
                    text: () => Promise.resolve(e.target.responseText),
                    json: () => Promise.resolve(JSON.parse(e.target.responseText))
                })

            xhr.onerror = reject

            if (xhr.upload)
                xhr.upload.onprogress = opts.onProgress

            xhr.send(opts.body)
        });
    }
    return createUploadLink({
        uri: config.graphQL,
        fetch: typeof window === 'undefined' ? global.fetch : customFetch
    });
}
export function authLink(context) {
    return  setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                Authorization: context.admin ? `Bearer ${context.admin}` : "",
                Authuser: context.user || "",
                Lang: context.lang
            }
        }
    });
}
export function apolloClient(context) {
    return  new ApolloClient({
        cache: new InMemoryCache({

        }),
        link: from([authLink(context), uploadLink()]),
    });
}

function ApolloWrapper({context}) {

  return (
    <div>
        <ApolloProvider client={apolloClient(context)}>
            <Wrappers context={context} />
        </ApolloProvider>
    </div>
  );
}
export default ApolloWrapper;
