import {useState} from "react";
import {useMutation} from "@apollo/client";
import {CreateWishlistAdded} from "../../_graphql/compiled/WishlistAdded";

//@@@ type=wrapper

const loadWishlist = () =>  {
    let wishlist = localStorage.getItem("wishlist");
    if(!wishlist) return [];
    try {
        wishlist = JSON.parse(wishlist);
    } catch (e) {
        return [];
    }
    return wishlist;
}

function PreloadWishlistWrapper({Next, context}) {
    const [wishlist, setWishlist] = useState(loadWishlist());
    const [createWishlistAdded] = useMutation(CreateWishlistAdded);

    const toggle = (id) => {
        id = parseInt(id);
        if(!id) return false;
        let newWishlist;
        if(wishlist.includes(id))   {
            newWishlist = wishlist.filter(item => item !== id);
        } else {
            console.log(context.checkCampaign(id))
            newWishlist = [...wishlist, id];
            createWishlistAdded({variables: {
                    product_id: id,
                    client: context.client,
                    session: context.session,
                    campaign: context.checkCampaign(id)
                }});
        }
        setWishlist(newWishlist);
        if(newWishlist.length > 0)      localStorage.setItem("wishlist", JSON.stringify(newWishlist));
        else                            localStorage.removeItem("wishlist");
    }

    return <Next context={{...context, wishlist, toggle}} />
}
export default PreloadWishlistWrapper;