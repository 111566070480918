import {gql} from "@apollo/client";
import {RESPONSE_FRAGMENT} from "../fragments/ResponseFragment";

export const WishlistAddedFragment = gql`
    fragment WishlistAddedFragment on WishlistAdded {
		id
		product_id
		client
		created_at
    }
`

export const WishlistAddedFragmentAdmin = gql`
    fragment WishlistAddedFragmentAdmin on WishlistAdded {
		id
		product_id
		client
		created_at
    }
`

export const CreateWishlistAdded = gql`
	${RESPONSE_FRAGMENT}
     mutation ($product_id: ID!, $client: String, $session: String, $campaign: String) {
         CreateWishlistAdded (product_id: $product_id, client: $client, session: $session, campaign: $campaign) {
			...ResponseFragment
         }
    }
`

