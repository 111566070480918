import {useQuery} from "@apollo/client";
import GraphQLErrorMessage from "../views/GraphQLErrorMessage";
import {GetCategories} from "./Category.js";

function useGetCategories(params = {}) {
    const {data, previousData, loading, error, refetch, variables} = useQuery(
        GetCategories, 
        {...params, notifyOnNetworkStatusChange: true}
    );
    
    let holder = (data && data['GetCategories']) ? data : previousData;
    const extracted = (holder) ? holder['GetCategories'] : [];
    
    return {
        data: extracted, 
        loading: (loading && !previousData), 
        refetch, 
        variables,
        error: error ? <GraphQLErrorMessage error={error} /> : null
    }
}
export default useGetCategories;
