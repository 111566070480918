import {gql} from "@apollo/client";

export const PAGINATOR_INFO_FRAGMENT = gql`
    fragment PaginatorInfoFragment on PaginatorInfo {
        currentPage
        lastPage
        count
        total
    }
`;

export const SIMPLE_PAGINATOR_INFO_FRAGMENT = gql`
    fragment SimplePaginatorInfoFragment on SimplePaginatorInfo {
        currentPage
        perPage
        count
    }
`;