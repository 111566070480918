import {useQuery} from "@apollo/client";
import GraphQLErrorMessage from "../views/GraphQLErrorMessage";
import {GetSettings} from "./Setting.js";

function useGetSettings(params = {}) {
    const {data, previousData, loading, error, refetch, variables} = useQuery(
        GetSettings, 
        {...params, notifyOnNetworkStatusChange: true}
    );
    
    let holder = (data && data['GetSettings']) ? data : previousData;
    const extracted = (holder) ? holder['GetSettings'] : [];
    
    return {
        data: extracted, 
        loading: (loading && !previousData), 
        refetch, 
        variables,
        error: error ? <GraphQLErrorMessage error={error} /> : null
    }
}
export default useGetSettings;
