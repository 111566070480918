import translations from "../../_translations";
import config from "../../_config";
import {useMemo} from "react";

//@@@ type=wrapper
//@@@ dependency=lang.MatchLangWrapper


function LangHooksWrapper({Next, context}) {
    const shorts = useMemo(() => {
        return config.languages.map(it => it.short);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config.languages]);

    const route = (path, params) => {
        if(path === "index")    {
            return config.languages[context.lang].value;
        }
        if(!context.routes[path])       {
            console.log("MISSING: ", `Ne postoji route: ${path}`);
            return config.languages[context.lang].value;
        }
        let p = context.routes[path].fullPaths[context.lang];
        if(p === null) return "";
        for(const key in params)    {
            p = p.replaceAll(`:${key}?`, params[key]).replaceAll(`:${key}`, params[key]);
        }
        if(p.includes("?")) {
            p = p.split("/").filter(it => !it.includes("?")).join("/");
        }
        return p;
    }

    const trans = (name) => {
        if (typeof name === 'string' || name instanceof String) {
            if(!translations[name]) {
                console.log("MISSING: ", `Ne postoji trans za: "${name}" lang: ${context.lang}`)
                return "*"+name;
            }
            if(!translations[name][shorts[context.lang]]) {
                return "*"+name;
            }
            return translations[name][shorts[context.lang]];
        }
        name = Object.values(name);
        if(!name[context.lang]) {
            console.log("MISSING: ", `Ne postoji trans za: "${name}" lang: ${context.lang}`)
            return "*"+name;
        }
        return name[context.lang];
    }

    return <Next context={{...context, trans, route}} />
}
export default LangHooksWrapper;