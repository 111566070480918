import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ContextWrapper from "./-     ContextWrapper";
import ScrollToTop from "./ScrollToTop";
import {useEffect, useState} from "react";

function CurrentRouteMiddleware({children, route, setCurrentRoute}) {

    useEffect(() => {
        setCurrentRoute(route);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route.paths]);

    return children;
}

function Router({context}) {
    const [currentRoute, setCurrentRoute] = useState(null);

    const renderRoute = (route, lang) => {
        if (route.children?.length > 0) {
            return <Route key={route.name + lang} path={route.paths[lang]} element={
                !route.layout
                    ? <ContextWrapper context={context} route={route} currentRoute={currentRoute} />
                    : context.widget(route.name)
                }>
                {route.children.map(child => renderRoute(child, lang))}
            </Route>
        }

        if(!route.name) {
            return null;
        }
        const routeElement = <CurrentRouteMiddleware route={route} setCurrentRoute={setCurrentRoute}>
            {context.widget(route.name)}
        </CurrentRouteMiddleware>

        if (route.paths[lang] === "") {
            return <Route index key={route.name + lang} element={routeElement}/>
        }
        if(!route.paths[lang])  {
            return null;
        }
        if(route.optionalPaths) {
            return context.trans(route.optionalPaths).map(p => {
                return <Route key={route.name + p} path={p} element={routeElement}/>
            });
        }
        if(route.name === undefined)    {
            return null;
        }
        return <Route key={route.name + lang} path={route.paths[lang]} element={routeElement}/>
    }

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                {Object.values(context.routes).map(route => {
                    if(route.layout) return null;
                    return Object.keys(route.paths).map(lang => {
                        return renderRoute(route, lang);
                    });
                })}
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
