
module.exports = {
    flow: {sr: "L/min"},
    height: {sr: "m"},
    cubic: {sr: "\t&#13221;/h"},
    atesti: {sr: "Atesti"},
    "uputstvo za upotrebu": {sr: "Uputstvo za upotrebu"},

    'route.cmsDashboard': {sr: 'tabla', en: 'dashboard'},
    'route.cmsProducts': {sr: '', en: 'products'},
    'route.cmsPricelists': {sr: 'cenovnici', en: 'pricelists'},
    'route.pricelists': {sr: 'cenovnici', en: 'pricelists'},
    'route.cmsDocuments': {sr: 'dokumentacija', en: 'documents'},
    'route.documents': {sr: 'dokumentacija', en: 'documents'},

    'dokumentacija': {sr: 'Dokumentacija', en: 'Documents'},
    'cenovnici': {sr: 'Cenovnici', en: 'Pricelists'},
    'po visini vodenog stuba': {sr: 'Po visini vodenog stuba', en: 'Po visini vodenog stuba'},
    'po protoku': {sr: 'Po protoku', en: 'Po protoku'},
}
