import {gql} from "@apollo/client";
import {GroupFragmentAdmin} from "./Group";

export const PageFragment = gql`
    fragment PageFragment on Page {
		id
		parent_id
		position
		menu
		type
		group_id
		page
		code
		active
		name
		slug
		seo_title
		seo_keywords
		seo_description
		locked
    }
`

export const PageFragmentAdmin = gql`
    fragment PageFragmentAdmin on Page {
		id
		parent_id
		position
		menu
		type
		group_id
		page
		code
		active
		name
		slug
		names
		slugs
		seo_title
		seo_keywords
		seo_description
		seo_titles
		seo_keywords_all
		seo_descriptions
		locked
    }
`

export const GetPages = gql`
	${PageFragment}
     query  {
         GetPages  {
			...PageFragment
			children 	 { 
				...PageFragment 
			}
         }
    }
`

export const GetPagesAdmin = gql`
	${PageFragmentAdmin}
	${GroupFragmentAdmin}
     query  {
         GetPages  {
			...PageFragmentAdmin
			group 	 { 
				...GroupFragmentAdmin 
			}
			children 	 { 
				...PageFragmentAdmin 
			}
         }
    }
`

export const CreatePage = gql`
	${PageFragment}
     mutation ($name: String!, $slug: String!, $menu: Int, $parent_id: ID) {
         CreatePage (name: $name, slug: $slug, menu: $menu, parent_id: $parent_id) {
			...PageFragment
         }
    }
`

export const UpdatePage = gql`
	${PageFragment}
     mutation ($id: ID!, $active: Boolean, $name: String, $slug: String, $type: Int, $menu: Int, $seo_title: String, $seo_keywords: String, $seo_description: String, $group_id: ID) {
         UpdatePage (id: $id, active: $active, name: $name, slug: $slug, type: $type, menu: $menu, seo_title: $seo_title, seo_keywords: $seo_keywords, seo_description: $seo_description, group_id: $group_id) {
			...PageFragment
         }
    }
`

export const DeletePage = gql`
	${PageFragment}
     mutation ($id: ID!) {
         DeletePage (id: $id) {
			...PageFragment
         }
    }
`

