import {useMemo} from "react";
import useGetSettings from "../../_graphql/compiled/useGetSettings";

//@@@ type=wrapper
//@@@ dependency=lang.MatchLangWrapper


function LoadSettingsWrapper({Next, context}) {
    const {data, loading, error} = useGetSettings();

    const settings = useMemo(() => {
        const holder = {};
        if(!data) return holder;
        for(let i=0; i<data.length; i++)    {
            holder[data[i].field] = data[i];
        }
        return holder;
    }, [data]);

    const setting = (name, full=false) => {
        if(!settings) return "";
        if(!name) return "";
        if(full)    {
            return settings[name] || {};
        }
        if(!settings[name]?.values[context.lang]) {
            return settings[name]?.values[0];
        }
        return settings[name]?.values[context.lang] || "";
    }

    if(loading)     return <Next context={{...context, settings, setting}} />
    if(error)       return error;

    return <Next context={{...context, settings, setting}} />
}
export default LoadSettingsWrapper;