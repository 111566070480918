import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

/*
    SCROLL PAGE TO TOP AFTER PAGE CHANGE
 */
function ScrollToTop() {
    const location = useLocation();
    const [prev, setPrev] = useState({pathname: "", search: ""});

    useEffect(() => {
        if(location.hash)   {
            setTimeout(() => {
                const id = location.hash.replace('#', '');
                const element = document.getElementById(id);
                console.log(id)
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
        if(location.search !== prev.search)  {
            window.scrollTo(0, 0);
        }
        if(location.pathname !== prev.pathname)  {
            window.scrollTo(0, 0);
        }
        setPrev({...prev, pathname: location.pathname, search: location.search});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return null;
}

export default ScrollToTop;
