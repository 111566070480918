import {gql} from "@apollo/client";
import {PAGINATOR_INFO_FRAGMENT} from "../fragments/PaginatorInfoFragment";
import {RESPONSE_FRAGMENT} from "../fragments/ResponseFragment";

export const StorageFragment = gql`
    fragment StorageFragment on Storage {
		id
		name
		orig_name
		mime
		width
		height
		alt
		tab
		bucket
		folder
    }
`

export const StorageFragmentAdmin = gql`
    fragment StorageFragmentAdmin on Storage {
		id
		name
		orig_name
		mime
		width
		height
		alt
		tab
		bucket
		folder
    }
`

export const GetStorage = gql`
	${StorageFragment}
	${PAGINATOR_INFO_FRAGMENT}
     query ($first: Int, $page: Int, $search: String, $tab: String) {
         GetStorage (first: $first, page: $page, search: $search, tab: $tab) {
			data {
			...StorageFragment
			}
			paginatorInfo {
				...PaginatorInfoFragment
			}
         }
    }
`

export const GetStorageAdmin = gql`
	${StorageFragmentAdmin}
	${PAGINATOR_INFO_FRAGMENT}
     query ($first: Int, $page: Int, $search: String, $tab: String) {
         GetStorage (first: $first, page: $page, search: $search, tab: $tab) {
			data {
			...StorageFragmentAdmin
			}
			paginatorInfo {
				...PaginatorInfoFragment
			}
         }
    }
`

export const UploadStorage = gql`
	${RESPONSE_FRAGMENT}
     mutation ($file: Upload!, $bucket: String!) {
         UploadStorage (file: $file, bucket: $bucket) {
			...ResponseFragment
         }
    }
`

export const DeleteStorage = gql`
	${StorageFragment}
     mutation ($ids: [ID!]!) {
         DeleteStorage (ids: $ids) {
			...StorageFragment
         }
    }
`

export const UpdateStorage = gql`
	${StorageFragment}
     mutation ($id: ID!, $tab: String) {
         UpdateStorage (id: $id, tab: $tab) {
			...StorageFragment
         }
    }
`

