import {from, useApolloClient} from "@apollo/client";
import config from "../../_config";
import translations from "../../_translations";
import {matchRoutes} from "react-router-dom";
import {authLink, uploadLink} from "../../_app/-  ApolloWrapper";
import {useMemo} from "react";

//@@@ type=wrapper
//@@@ dependency=pages.LoadPagesWrapper

function MatchLangWrapper({Next, context}) {
    const client = useApolloClient();

    const shorts = config.languages.map(it => it.short);

    const getFullPaths = (route, acc) => {
        if(route.layout)    {
            const paths = getPaths(context.routes[route.layout].path);
            for(let i=0; i<shorts.length; i++)  {
                const slash = paths[i].endsWith('/') ? '' : '/';
                paths[i] += slash + acc[i];
            }
            return getFullPaths(context.routes[route.layout], paths);
        }
        return acc;
    }
    const getPaths = (path) => {
        if(Array.isArray(path))  {
            return path.slice(0, shorts.length);
        }
        const holder = [];
        for(let i=0; i<shorts.length; i++)  {
            holder.push(translations[path][shorts[i]]);
        }
        return holder;
    }

    const test = useMemo(() => {
        const matchHolder = [];
        for(let routeName in context.routes)    {
            const route = context.routes[routeName];

            if(route.layout)   {
                if(!context.routes[route.layout]) continue;
                if(!context.routes[route.layout].children) context.routes[route.layout].children = [];
                context.routes[route.layout].children.push(route);
            }
            route.paths = getPaths(route.path);
            route.fullPaths = getFullPaths(route, route.paths);
            if(route.names) {
                route.names = getPaths(route.names);
            }
            for(const short in route.fullPaths) {
                if(route.optionalPaths) {
                    let paths;
                    if (typeof route.optionalPaths === 'string' || route.optionalPaths instanceof String) {
                        paths = Object.values(translations[route.optionalPaths])[short];
                    } else  {
                        paths = Object.values(route.optionalPaths)[short];
                    }
                    for(let op=0; op<paths.length; op++)    {
                        matchHolder.push({path: paths[op], lang: short});
                    }
                    continue;
                }
                matchHolder.push({path: route.fullPaths[short], lang: short});
            }
        }
        return matchRoutes(matchHolder, window.location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.routes]);

    let defaultLang = context.lang;
    if(test && test.length > 0) {
        const lang = test[0]?.route?.lang;
        if(lang !== context.lang)  {                      // Update apollo client with lang
            client.setLink(from([authLink({...context, lang: lang}), uploadLink()]));
            defaultLang = lang;
        }
    }

    return <Next context={{...context, lang: defaultLang}} />
}
export default MatchLangWrapper;