import React, {useContext, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {MinusSquare, PlusSquare} from "react-feather";
import indexIcon from "./index.png";
import AppContext from "../../../../../../../modules/_app/AppContext";

//@@@ load=import

function MenuItem({item, index, mobileCloseRef}) {
    const {route} = useContext(AppContext);
    const [dropdown, setDropdown] = useState(false);

    const dropDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDropdown(!dropdown);
    }

    const clicked = () => {
        if(mobileCloseRef) mobileCloseRef.current();
    }

    return <div key={item.id} className="md:inline-block border-b md:border-0 group">
        <NavLink to={item.path} onClick={clicked} className="flex items-center justify-between
         py-2 md:py-3 px-3 block md:inline-block w-full">
            <span className="under_active">
                {index
                    ? <>
                    <span className="hidden md:inline-block relative" style={{height: 16, bottom: 3}}>
                       <img src={indexIcon} alt="Index" width={36} />
                    </span>
                    <span className="md:hidden">{item.name}</span>
                    </>
                    : item.name}
            </span>

            {item.children.length > 0 &&
            <button onClick={dropDown} className="p-0 md:hidden">
                {dropdown
                    ? <MinusSquare strokeWidth={1} />
                    : <PlusSquare strokeWidth={1} />}
            </button>}
        </NavLink>

        {item.children.length > 0 &&
        <div className={`md:absolute hidden ${dropdown ? "!block" : ""} bg-[#F4f4f4] group-hover:block bg-white text-black left-0 right-0`}>
            <div className="md:flex justify-center items-center">
                {item.children.map(child => {
                    return <div key={child.id} className="py-3 px-8 md:px-5">
                        <Link to={route(`page.${child.id}`)} onClick={clicked} className="link">
                            {child.name}
                        </Link>
                    </div>
                })}
            </div>
        </div>}
    </div>
}

function HeaderMenu({mobileCloseRef})   {
    const {widget} = useContext(AppContext);
    const header = widget('pages.useGetMenu', 1);
    return (
        <nav>
            {header && header.map((item, index) => {
                return <MenuItem key={item.id} index={index===0} item={item} mobileCloseRef={mobileCloseRef} />
            })}
        </nav>
    )
}
export default HeaderMenu;