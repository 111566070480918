
function GraphQLErrorMessage({error, data}) {
    if(data)   {
        const resp = data[Object.keys(data)[0]];
        if(resp.error)  {
            return <div className="bg-red-600 text-white px-4 py-2 mt-1 rounded-md">
                {resp.error}
            </div>
        }
    }
    if(!error?.message)   {
        return null;
    }
    return <div className="bg-red-600 text-white px-4 py-2 mt-1 rounded-md">
        {error.message}
    </div>
}
export default GraphQLErrorMessage;
