import Router from "./-    Router";

function Wrappers({context}) {

    const wrappers = [...context.wrappers.map(it => it.element), Router];
    wrappers.map((item, i) => item.defaultProps = {Next: wrappers[i+1] || null});
    const First = wrappers[0];

    return <First context={context} />
}

export default Wrappers;
