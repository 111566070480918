import {useMemo} from "react";
import useGetCategories from "../../_graphql/compiled/useGetCategories";

//@@@ type=wrapper

function LoadCategoriesWrapper({Next, context}) {

    const {data, error} = useGetCategories();

    const looper = (arr) => {
        const categories = [];
        const search = {};
        for(let i=0; i<arr.length; i ++) {
            const item = {...arr[i], children: [], breadcrumbs: [arr[i]], path: {slug: arr[i].slug},
                orig_count: arr[i].products_count, shop_orig_count: arr[i].shop_products_count, ids: [arr[i].id]};
            search[item.id] = item;
            categories.push(item);
        }
        for(let i=0; i<categories.length; i ++) {
            const item = categories[i];
            if(item.parent_id)  {
                const parent1 = search[item.parent_id];
                if(!parent1) continue;
                item.breadcrumbs.unshift(parent1);
                item.path["parent1"] = parent1.slug;
                parent1.children.push(item);
                parent1.ids.push(item.id);
                parent1.products_count = parent1.products_count + item.orig_count;
                parent1.shop_products_count = parent1.shop_products_count + item.shop_orig_count;
                if(parent1.parent_id)   {
                    const parent2 = search[parent1.parent_id];
                    if(!parent2) continue;
                    item.breadcrumbs.unshift(parent2);
                    item.path["parent2"] = parent2.slug;
                    parent2.ids.push(item.id);
                    parent2.products_count = parent2.products_count + item.orig_count;
                    parent2.shop_products_count = parent2.shop_products_count + item.shop_orig_count;
                    if(parent2.parent_id)   {
                        const parent3 = search[parent2.parent_id];
                        if(!parent3) continue;
                        item.breadcrumbs.unshift(parent3);
                        item.path["parent3"] = parent3.slug;
                        parent3.ids.push(item.id);
                        parent3.products_count = parent3.products_count + item.orig_count;
                        parent3.shop_products_count = parent3.shop_products_count + item.shop_orig_count;
                    }
                }
            }
        }
        return [categories, search];
    }
    const [categories, search] = useMemo(() => looper(data), [data]);
    if(error) return error;

    const categoryById = (categoryId) => {
        if(!categoryId) return null;
        if(!search[categoryId]) return null;
        return search[categoryId];
    }
    const categoryBySlug = (categorySlug) => {
        if(!categorySlug) return null;
        return categories.find(item => item.slug === categorySlug);
    }

    return <Next context={{...context, categories, looper, categoryById, categoryBySlug}} />
}
export default LoadCategoriesWrapper;