import {widgets} from '../compiled';
import {randString} from "../../framework/helpers";
import ApolloWrapper from "./-  ApolloWrapper";
import {Suspense} from "react";
import {providerCheckAdmin} from "../cms/providers/_adminProvider";
import {Toaster} from "react-hot-toast";
import config from "../_config";
import {providerCheckUser} from "../users/providers/_userProvider";


function App() {

    const context = {
        session: randString(6),
        isMobile: checkIsMobile(),
        client: checkClientCode(),
        admin: providerCheckAdmin(),
        user: providerCheckUser(),
        lang: 0,
        routes: {},
        wrappers: [],
        middlewares: [],
        widget: widget,
        module: module
    };

    for (let key in widgets) {
        const widget = widgets[key];
        if (widget.path !== undefined) {
            context.routes[widget.name] = widget;
        }
        if (widget.type === 'wrapper') {
            context.wrappers.push(widget);
        }
        if (widget.type === 'middleware') {
            context.middlewares.push(widget);
        }
    }

    return (
        <>
            <Toaster />
            <ApolloWrapper context={context} />
        </>
    );
}

const checkIsMobile = () => {
    let width;
    if (window.outerHeight) width = window.outerWidth;
    else width = document.body.clientWidth;
    return width < 768;
}
const checkClientCode = () => {
    let code = localStorage.getItem("client");
    if (!code) {
        const abcd = ['A', 'D', 'f', 'T', 'q', 'M', 'c', 'S', 'o', 'P'];
        const unique = Date.now().toString();
        code = randString(4);
        for (var i = 0; i < unique.length; i++) {
            code = code + abcd[unique.charAt(i)];
        }
        localStorage.setItem("client", code);
    }
    return code;
}
const widget = (path, params = false) => {
    if (!widgets[path] || !widgets[path].element) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('MISSING: ', `Ne postoji widget: ${path}`)
        }
        return null;
    }
    const Element = widgets[path].element;
    const arr = path.split(".");
    if (arr[1][0] === arr[1][0].toUpperCase()) {
        return <Suspense fallback={widgets[path].loader || <div/>}>
            <Element {...params} />
        </Suspense>
    }
    if (params !== false) {
        return widgets[path].element(params);
    }
    return widgets[path].element;
}
const module = (name) => {
    return config.modules.includes(name);
}

export default App;
