
function server(path) {
    if (path.startsWith("/")) path = path.substring(1);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `http://localhost:5000/${path}`;
    }
    return `https://pumpe.rs/${path}`;
}

module.exports =  {
    server: server,
    graphQL: server("graphql"),

    languages: [
        {id: 0, short: 'sr', value: "/", name: "Srpski", label: "RS"},
    ],
    
    pagesLvl: 2,

    banners: {
        "shopmenu": {
            name: "shop strana",
            description: "shop strana desc",
        },
    },
    cmsEntryPoint: "products.CmsProducts",

    accentColor: "#ef5a35",
    storageImageFolder: server("storage/pumpe/slike"),
    storageFileFolder: server("storage/pumpe/fajlovi"),

    modules: ["banners","blog","brands","categories","cms","design","groups",
        "lang","pages","products","public","pwa","seo","settings","storage",
        "textEditor","wishlist","files"],

    designTypes: {
        slider: {type: 1, name: "veliki slajder"},
        banners: {type: 2, name: "baneri"},
        products: {type: 3, name: "carousel proizvoda"},
        categories: {type: 5, name: "kategorije u carousel-u"},
        editor: {type: 6, name: "TEXT editor"},
        brands: {type: 7, name: "brendovi u carousel-u"},
        blog: {type: 8, name: "BLOG carousel"},
        cards: {type: 9, name: "text i slika"},
        calculator: {type:100, name: ["Kalkulator", "cac"], widget: "products.Calculator"},
    },

    product: {
        connected: true,
        recommended: true,
        gallery: true,
        specifications: true,
        youtube: true,
    },

    productsOrders: [
        {title: "po relevantnosti", value: ""},
        {title: "po pregledima", value: 8, admin: true},
        {title: "po nazivu", value: 2},
        {title: "po visini vodenog stuba", value: 100},
        {title: "po protoku", value: 101},
    ],

    documentTypes: {
        1: {type: 1, name: "atesti"},
        2: {type: 2, name: "uputstvo za upotrebu"},
    }
}