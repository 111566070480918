import {useContext} from 'react';
import AppContext from "./AppContext";


function Middlewares({route}) {
    const {widget, middlewares} = useContext(AppContext);

    const wrapLooper = (wrapped, index) => {
        if (index === undefined) index = 0;
        if (!middlewares[index]) return wrapped;
        return widget(middlewares[index].name, {
            children: wrapLooper(wrapped, index + 1)
        })
    }

    return wrapLooper(widget(route.name))
}

export default Middlewares;
