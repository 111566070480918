import config from "../../_config";

function storageFile(file) {
    if(file && file.name.endsWith(".pdf")) {
        return config.server(`storage/000-pdf.png`);
    }
    return config.server("storage/000-file.png");
}
function noImage() {
    return config.server("storage/000-no-image.png");
}
export default function image(image, lg=false) {
    if(!image)  {
        return noImage();
    }
    if (typeof image === "string") {
        return `${config.storageImageFolder}/${image}`;
    }

    if(!image.width)    {
        return storageFile(image);
    }
    let imageName = image.name;
    if(lg)  {
        const imageArr = imageName.split(".");
        const mime = imageArr.pop();
        imageName = imageArr.join(".") + "-lg." + mime;
    }

    return `${config.storageImageFolder}/${image.bucket}/${image.folder}/${imageName}`;
}
