import {useMemo} from "react";
import useGetPagesAdmin from "../../_graphql/compiled/useGetPagesAdmin";
import config from "../../_config";

//@@@ type=wrapper

function LoadPagesWrapper({Next, context}) {
    const {data, loading, error} = useGetPagesAdmin();

    const pages = useMemo(() => {
        const holder = {};
        if(!data) return holder;

        for(let i=0; i<data.length; i++)    {
            const item = data[i];
            if(!item.active) continue;
            if(item.page && context.routes[item.page])   {
                context.routes[item.page].page = item;
                holder['page.'+item.id] = {
                    path: item.slugs,
                    page: item
                }
                continue;
            }
            let path = item.slugs;
            let name = 'pages.PageTemplate';
            let optionalPaths = null;
            if(item.type === 2) {
                path =  item.slugs.map(s => `${s}/:parent2?/:parent1?/:slug?`);
                name = 'pages.ShopTemplate';
                optionalPaths = {};
                for(let i=0; i<config.languages.length; i++)    {
                    const slug = item.slugs[i];
                    optionalPaths[config.languages[i].short] = [slug, `${slug}/:slug`, `${slug}/:parent1/:slug`, `${slug}/:parent2/:parent1/:slug`];
                }
            }
            holder['page.'+item.id] = {
                layout: 'public.PublicLayout',
                path: path,
                optionalPaths: optionalPaths,
                name: name,
                page: item
            };
        }
        return holder;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if(loading)     return null;
    if(error)       return error;

    return <Next context={{...context, routes: {...context.routes, ...pages}, pages: pages}} />
}
export default LoadPagesWrapper;