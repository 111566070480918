import AppContext from "../../_app/AppContext";
import {useContext} from "react";
import {ogImage, ogUrl, resetStructureData,seoDescription, seoKeywords, seoTitle} from "../providers/_seoProvider";
import seoAll from "../providers/seoAll";

//@@@ type=middleware


function RouteSeoMiddleware({children})   {
    const {currentRoute, setting, lang} = useContext(AppContext);

    resetStructureData();
    ogUrl();
    ogImage();
    seoAll(setting("default SEO title"), setting("default SEO description"), setting("default SEO keywords"));

    if(currentRoute?.page)   {
        if(currentRoute.page.seo_titles[lang]) seoTitle(currentRoute.page.seo_titles[lang]);
        if(currentRoute.page.seo_keywords_all[lang]) seoKeywords(currentRoute.page.seo_keywords_all[lang]);
        if(currentRoute.page.seo_descriptions[lang]) seoDescription(currentRoute.page.seo_descriptions[lang]);
    }
    return children;
}

export default RouteSeoMiddleware;
