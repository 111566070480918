import {useQuery} from "@apollo/client";
import GraphQLErrorMessage from "../views/GraphQLErrorMessage";
import {GetPagesAdmin} from "./Page.js";

function useGetPagesAdmin(params = {}) {
    const {data, previousData, loading, error, refetch, variables} = useQuery(
        GetPagesAdmin, 
        {...params, notifyOnNetworkStatusChange: true}
    );
    
    let holder = (data && data['GetPages']) ? data : previousData;
    const extracted = (holder) ? holder['GetPages'] : [];
    
    return {
        data: extracted, 
        loading: (loading && !previousData), 
        refetch, 
        variables,
        error: error ? <GraphQLErrorMessage error={error} /> : null
    }
}
export default useGetPagesAdmin;
