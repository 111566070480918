import AppContext from "./AppContext";
import Middlewares from "./-      Middlewares";

function ContextWrapper({context, route, currentRoute}) {

    return (
        <AppContext.Provider value={{...context, currentRoute}}>
            <Middlewares route={route}/>
        </AppContext.Provider>
    );
}

export default ContextWrapper;
